import {
  ref,
} from "vue";

export default function ContrastAPI({
  model = ref({}),
  setCookieContrastButtonActive = () => {},
  setCookieContrastButtonInactive = () => {},
}) {
  const classForBody = "contrast_accessibility";

  const addContrast = () => {
    document.body.classList.add(classForBody);
  };

  const removeContrast = () => {
    document.body.classList.remove(classForBody);
  };

  const initContrast = () => {
    if (model.value.contrast_button) {
      addContrast();
    }
  };

  const setContrast = () => {
    if (model.value.contrast_button) {
      addContrast();
      setCookieContrastButtonActive();
    } else {
      removeContrast();
      setCookieContrastButtonInactive();
    }
  };

  return {
    initContrast,
    setContrast,
  };
}
