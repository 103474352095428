import {
  computed,
  ref,
  toRef,
} from "vue";

import { toFormElementFromParameter } from "../../../../../global/functions/mappingForParameterToFormElement";

export default function OptionsAPI(props) {
  const options = toRef(props, "options");

  const jsonParam = ref({
    bez: "Pauschale-Konfiguration pro Periode",
    default: null,
    help_text: null,
    readonly: false,
    options: {
      allow_null: false,
      required: true
    },
    typ: "validated_json",
    many: true,
    as_json: false,
    unique_fields: [],
    key_field: null,
    pos_field: null,
    min_length: null,
    max_length: null,
    element_label: "Pauschale",
    element_template: "{{element_label}} (Fallback: {{fallback}})",
    element_errors: true,
    deactivate_ordering: false,
    deactivate_preview: false,
    hide_pos: false,
    fields: {
      min_prozent_zuschuss: {
        bez: "_LBL_GR_PAUSCHALE_MIT_PERIODEN_MIN_PROZENT_ZUSCHUSS_",
        typ: "decimal",
        prio: 20,
        options: {
          max_value: 100,
          min_value: -100,
          decimal_places: 2,
          max_digits: 6,
          required: false,
          allow_null: true,
        },
      },
      max_prozent_zuschuss: {
        bez: "_LBL_GR_PAUSCHALE_MIT_PERIODEN_MAX_PROZENT_ZUSCHUSS_",
        typ: "decimal",
        prio: 30,
        options: {
          required: false,
          allow_null: false,
          max_value: 100,
          min_value: -100,
          decimal_places: 2,
          max_digits: 6,
        },
      },
      min_pauschale: {
        bez: "_LBL_GR_PAUSCHALE_MIT_PERIODEN_MIN_PAUSCHALE_",
        typ: "decimal",
        prio: 40,
        options: {
          max_digits: 10,
          decimal_places: 2,
          required: false,
          allow_null: true,
        },
      },
      max_pauschale: {
        bez: "_LBL_GR_PAUSCHALE_MIT_PERIODEN_MAX_PAUSCHALE_",
        typ: "decimal",
        prio: 50,
        options: {
          max_digits: 10,
          decimal_places: 2,
          required: false,
          allow_null: true,
        },
      },
      vergl_prozent_zuschuss: {
        bez: "_LBL_GR_PAUSCHALE_MIT_PERIODEN_VERGL_PROZENT_ZUSCHUSS_",
        typ: "boolean",
        prio: 60,
        default: true,
        options: {
          help_text: "_TXT_GR_PAUSCHALE_MIT_PERIODEN_VERGL_PROZENT_ZUSCHUSS_HELP_TEXT_"
        },
      },
      fallback: {
        prio: 70,
        bez: "_LBL_GR_PAUSCHALE_MIT_PERIODEN_PARAM_FALLBACK_",
        default: false,
        help_text: "_TXT_GR_PAUSCHALE_MIT_PERIODEN_PARAM_FALLBACK_HELP_TEXT_",
        readonly: false,
        options: {
          allow_null: false,
          required: true
        },
        typ: "boolean"
      },
    },
    prio: 5,
    wert: null
  });

  const jsonOptions = computed(() => {
    return toFormElementFromParameter({
      obj: { dimensionen: jsonParam.value },
      showLabel: true,
      staticOptions: {
        required: options.value?.required,
        options: options.value?.options
      }
    }).list[0];
  });

  return {
    jsonOptions,
  };
}
