import UIDynamischeTabelleModalCreateOrUpdate from "../../../DynamischeTabelle/Modals/UIDynamischeTabelleModalCreateOrUpdate/UIDynamischeTabelleModalCreateOrUpdate.js";
import OptionsMixin from "./OptionsMixin";

// @vue/component
export default {
  name: "ModalCreateOrUpdate",
  mixins: [
    UIDynamischeTabelleModalCreateOrUpdate,
    OptionsMixin,
  ],
};
