import {
  ref,
} from "vue";

export default function ModelAPI() {
  const model = ref({
    contrast_button: false,
    pop_up_message_timeout: 5,
  });

  return {
    model,
  };
}
