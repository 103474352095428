import {
  ref,
} from "vue";

export default function ModalAPI({
  setModelFromCookie = () => {},
}) {
  const isModalOpen = ref(false);

  const openModal = () => {
    setModelFromCookie();
    isModalOpen.value = true;
  };

  const closeModal = () => {
    isModalOpen.value = false;
  };

  return {
    closeModal,
    isModalOpen,
    openModal,
  };
}
