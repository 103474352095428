import AButton from "aloha-vue/src/AButton/AButton";
import AModalForm from "aloha-vue/src/AModalForm/AModalForm";
import FormElement from "../../global/components/FormElement/FormElement.vue";
import PuxTranslate from "../../global/components/PuxTranslate/PuxTranslate.vue";

import ContrastAPI from "./compositionAPI/ContrastAPI";
import CookiesAPI from "./compositionAPI/CookiesAPI";
import DataFormAPI from "./compositionAPI/DataFormAPI";
import InMobileAPI from "./compositionAPI/InMobileAPI";
import ModalAPI from "./compositionAPI/ModalAPI";
import ModelAPI from "./compositionAPI/ModelAPI";
import SaveAPI from "./compositionAPI/SaveAPI";

// @vue/component
export default {
  name: "TheAccessibilityButton",
  components: {
    AButton,
    FormElement,
    AModalForm,
    PuxTranslate,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: "btn_accessibility_modal",
    },
    inMobile: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const {
      dataForm,
    } = DataFormAPI();

    const {
      model,
    } = ModelAPI();

    const {
      setCookieContrastButtonActive,
      setCookieContrastButtonInactive,
      setCookieForPopUpMessageTimeout,
      setModelFromCookie,
    } = CookiesAPI({
      model,
    });

    const {
      initContrast,
      setContrast,
    } = ContrastAPI({
      model,
      setCookieContrastButtonActive,
      setCookieContrastButtonInactive,
    });

    const {
      btnIconClass,
    } = InMobileAPI(props);

    const {
      closeModal,
      isModalOpen,
      openModal,
    } = ModalAPI({
      setModelFromCookie,
    });

    const {
      save,
      setPopUpMessageTimeout,
    } = SaveAPI({
      closeModal,
      model,
      setCookieForPopUpMessageTimeout,
      setContrast,
    });

    setModelFromCookie();
    initContrast();
    setPopUpMessageTimeout();

    return {
      dataForm,
      closeModal,
      isModalOpen,
      btnIconClass,
      model,
      openModal,
      save,
    };
  },
};
