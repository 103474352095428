export default function DataFormAPI() {
  const dataForm = [
    {
      id: "contrast_button",
      type: "switch",
      label: "_LBL_ACCESSIBILITY_CONTRAST_",
      trueLabel: "_LBL_ACCESSIBILITY_CONTRAST_TRUE_",
      falseLabel: "_LBL_ACCESSIBILITY_CONTRAST_FALSE_",
      helpText: "_TXT_ACCESSIBILITY_CONTRAST_HELP_TEXT_",
    },
    {
      id: "pop_up_message_timeout",
      type: "integerNonNegative",
      label: "_LBL_ACCESSIBILITY_POP_UP_MESSAGE_TIMOUT_",
      isLabelFloat: false,
      helpText: "_TXT_ACCESSIBILITY_POP_UP_MESSAGE_TIMOUT_HELP_TEXT_",
      modelUndefined: 0,
    },
  ];

  return {
    dataForm,
  };
}
