import {
  ref,
} from "vue";

import ANotificationAPI from "aloha-vue/src/compositionAPI/ANotificationAPI";

const MILLISECONDS_IN_SECOND = 1000;

export default function SaveAPI({
  closeModal = () => {},
  model = ref({}),
  serCookieForPopUpMessageTimeout = () => {},
  setContrast = () => {},
}) {
  const {
    addNotification,
    setNotificationTimeout,
  } = ANotificationAPI();

  const setPopUpMessageTimeout = () => {
    setNotificationTimeout(model.value.pop_up_message_timeout * MILLISECONDS_IN_SECOND);
  };

  const save = () => {
    model.value.pop_up_message_timeout = model.value.pop_up_message_timeout || 0;
    setContrast();
    setPopUpMessageTimeout();
    serCookieForPopUpMessageTimeout();

    closeModal();
    addNotification({
      text: "_MSG_ACCESSIBILITY_MODAL_SAVE_SUCCESS_",
    });
  };

  return {
    save,
    setPopUpMessageTimeout,
  };
}
