import PuxCloak from "../../../../../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../../../../../global/components/PuxTranslate/PuxTranslate.vue";
import UiDynamischeTabellePeriodenReadOnlyElement
  from "./UiDynamischeTabellePeriodenReadOnlyElement/UiDynamischeTabellePeriodenReadOnlyElement.vue";

import HttpMixin from "../../../../../../global/mixins/HttpMixin";

// @vue/component
export default {
  name: "UiDynamischeTabellePeriodenReadOnly",
  components: {
    PuxCloak,
    PuxTranslate,
    UiDynamischeTabellePeriodenReadOnlyElement,
  },
  mixins: [
    HttpMixin,
  ],
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    className: {
      type: String,
      default: "",
    },
    model: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
    dependencyValue: {
      type: [String, Number, Boolean, Array, Object, Date, Function, Symbol],
      default: undefined,
    },
  },
};
