import {
  ref,
} from "vue";

import Cookies from "js-cookie";
const ONE_YEAR = 365;

export default function CookiesAPI({
  model = ref({}),
}) {
  const cookieNames = {
    contrast: "accessibility_contrast",
    popUpMessageTimeout: "accessibility_pop_up_message_timeout",
  };

  const setModelFromCookie = () => {
    model.value = {
      contrast_button: Cookies.get(cookieNames.contrast) === "true",
      pop_up_message_timeout: +(Cookies.get(cookieNames.popUpMessageTimeout) || 5),
    };
  };

  const setCookieContrastButtonActive = () => {
    Cookies.set(cookieNames.contrast, true, { expires: ONE_YEAR });
  };

  const setCookieContrastButtonInactive = () => {
    Cookies.set(cookieNames.contrast, false, { expires: ONE_YEAR });
  };

  const setCookieForPopUpMessageTimeout = () => {
    Cookies.set(cookieNames.popUpMessageTimeout, model.value.pop_up_message_timeout, { expires: ONE_YEAR });
  };

  return {
    setCookieContrastButtonActive,
    setCookieContrastButtonInactive,
    setCookieForPopUpMessageTimeout,
    setModelFromCookie,
  };
}
