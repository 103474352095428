export const LIST = [
  {
    label: "Text",
    value: "text",
    options: [
      "label",
      "required",
      "styles",
      "width",
      "grow",
      "pdf_width",
      "pdf_header_alignment",
      "max_length",
      "id",
      "readonly",
      "hide",
    ],
  },
  {
    label: "Email",
    value: "email",
    options: [
      "label",
      "required",
      "styles",
      "width",
      "grow",
      "max_length",
      "hide",
    ],
  },
  {
    label: "Ganzzahl",
    value: "integer",
    options: [
      "label",
      "required",
      "styles",
      "width",
      "grow",
      "pdf_width",
      "pdf_header_alignment",
      "min_value_int",
      "max_value_int",
      "validation_expression",
      "validation_error_message",
      "sum",
      "id",
      "readonly",
      "hide",
    ],
  },
  {
    label: "Kommazahl",
    value: "decimal",
    options: [
      "label",
      "required",
      "styles",
      "width",
      "grow",
      "pdf_width",
      "pdf_header_alignment",
      "max_digits",
      "decimal_places",
      "min_value",
      "max_value",
      "validation_expression",
      "validation_error_message",
      "sum",
      "id",
      "readonly",
      "hide",
    ],
  },
  {
    label: "Währung",
    value: "currency",
    options: [
      "label",
      "required",
      "styles",
      "width",
      "grow",
      "pdf_width",
      "pdf_header_alignment",
      "max_digits",
      "decimal_places",
      "min_value",
      "max_value",
      "validation_expression",
      "validation_error_message",
      "sum",
      "id",
      "readonly",
      "hide",
    ],
  },
  {
    label: "Auswahlliste",
    value: "single_choice",
    options: [
      "label",
      "required",
      "styles",
      "width",
      "grow",
      "pdf_width",
      "pdf_header_alignment",
      "katalog",
      "komplett",
      "searchGlobal",
      "searchParameter",
      "ids",
      "id",
      "readonly",
      "hide",
    ],
  },
  {
    label: "Mehrfachauswahl",
    value: "multi_select",
    options: [
      "label",
      "required",
      "styles",
      "width",
      "grow",
      "pdf_width",
      "pdf_header_alignment",
      "katalog",
      "komplett",
      "searchGlobal",
      "searchParameter",
      "ids",
      "id",
      "readonly",
      "hide",
    ],
  },
  {
    label: "Objektbezogene Auswahlliste",
    value: "single_choice_objektkatalog",
    options: [
      "label",
      "required",
      "styles",
      "width",
      "grow",
      "pdf_width",
      "pdf_header_alignment",
      "objektkatalog",
      "id",
      "readonly",
      "hide",
    ],
  },
  {
    label: "Datum",
    value: "date",
    options: [
      "label",
      "uhrzeit",
      "required",
      "styles",
      "width",
      "grow",
      "pdf_width",
      "pdf_header_alignment",
      "id",
      "validation_expression",
      "validation_error_message",
      "readonly",
      "hide",
    ],
  },
  {
    label: "Berechnetes Datum",
    value: "date_expression",
    options: [
      "label",
      "required",
      "styles",
      "width",
      "grow",
      "pdf_width",
      "pdf_header_alignment",
      "id",
      "berechnung_expression",
      "hide",
    ],
  },
  {
    label: "Text mit Editor",
    value: "richtext",
    options: [
      "label",
      "required",
      "styles",
      "width",
      "grow",
      "pdf_width",
      "pdf_header_alignment",
      "max_length",
      "id",
      "readonly",
      "hide",
    ],
  },
  {
    label: "Boolean",
    value: "boolean",
    options: [
      "label",
      "required",
      "styles",
      "width",
      "grow",
      "pdf_width",
      "pdf_header_alignment",
      "anzeigemodus",
      "trueLabel",
      "falseLabel",
      "defaultValue",
      "id",
      "readonly",
      "hide",
    ],
  },
  {
    label: "Dokument",
    value: "document",
    options: [
      "label",
      "buttonText",
      "required",
      "styles",
      "width",
      "grow",
      "pdf_width",
      "pdf_header_alignment",
      "dokart",
      "isPreview",
      "allowed_mime_type",
      "show_mime_type",
      "id",
      "readonly",
      "hide",
    ],
  },
  {
    label: "Berechnungsformel",
    value: "math_expression",
    options: [
      "label",
      "required",
      "styles",
      "width",
      "grow",
      "pdf_width",
      "pdf_header_alignment",
      "expression",
      "max_digits",
      "decimal_places",
      "min_value",
      "max_value",
      "validation_expression",
      "validation_error_message",
      "sum",
      "hide",
      "id",
    ],
  },
  {
    label: "Dezimalzahl durch Signalempfang",
    value: "decimal_signal",
    options: [
      "label",
      "required",
      "styles",
      "width",
      "grow",
      "pdf_width",
      "pdf_header_alignment",
      "signal_regex",
      "signal_filter",
      "max_digits",
      "decimal_places",
      "min_value",
      "max_value",
      "hide",
      "id",
    ],
  },
  {
    label: "Dezimalzahl aus ausgewähltem objektbezogenen Element",
    value: "decimal_objektkatalog_data",
    options: [
      "label",
      "required",
      "styles",
      "width",
      "grow",
      "pdf_width",
      "pdf_header_alignment",
      "max_digits",
      "decimal_places",
      "objektkatalog_spalte_id",
      "objektkatalog_data_id",
      "sum",
      "hide",
      "id",
    ],
  },
  {
    label: "Text aus ausgewähltem objektbezogenen Element",
    value: "text_objektkatalog_data",
    options: [
      "label",
      "required",
      "styles",
      "width",
      "grow",
      "pdf_width",
      "pdf_header_alignment",
      "max_length",
      "objektkatalog_spalte_id",
      "objektkatalog_data_id",
      "hide",
      "id",
    ],
  },
  {
    label: "Dezimalzahl aus ausgewähltem Katalogeintrag",
    value: "decimal_katalog_data",
    options: [
      "label",
      "required",
      "styles",
      "width",
      "grow",
      "pdf_width",
      "pdf_header_alignment",
      "max_digits",
      "decimal_places",
      "katalog_spalte_id",
      "katalog_data_id",
      "sum",
      "hide",
      "id",
    ],
  },
  {
    label: "Text aus ausgewähltem Katalogeintrag",
    value: "text_katalog_data",
    options: [
      "label",
      "required",
      "styles",
      "width",
      "grow",
      "pdf_width",
      "pdf_header_alignment",
      "max_length",
      "katalog_spalte_id",
      "katalog_data_id",
      "hide",
      "id",
    ],
  },
  {
    label: "Benutzer",
    value: "user",
    options: [
      "label",
      "styles",
      "width",
      "grow",
      "pdf_width",
      "pdf_header_alignment",
      "hide",
      "id",
    ],
  },
];
