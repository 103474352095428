import UiValidatedJson from "../../../../global/components/ui/UiValidatedJson/UiValidatedJson.vue";

import UIComponentMixin from "../../../../global/components/ui/UIComponentMixin";

import OptionsAPI from "./compositionAPI/OptionsAPI";

// @vue/component
export default {
  name: "UiPauschalePeriodenConfig",
  components: {
    UiValidatedJson
  },
  mixins: [
    UIComponentMixin,
  ],
  setup(props) {
    const {
      jsonOptions
    } = OptionsAPI(props);

    return {
      jsonOptions,
    };
  }
};
