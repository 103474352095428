import {
  computed,
  toRef,
} from "vue";

export default function InMobileAPI(props) {
  const inMobile = toRef(props, "inMobile");

  const btnIconClass = computed(() => {
    return inMobile.value ? undefined : "icon_large a_m_0";
  });

  return {
    btnIconClass,
  };
}
