import {
  ref,
} from "vue";

import {
  debounce,
  get,
} from "lodash-es";

export const asideFixedHeight = ref(0);
export const navbarFixedHeight = ref(0);
export const isFooterFixed = ref(false);
export const footerHeight = ref(0);
const footer = ref(undefined);
const navbarFixedTop = ref(undefined);
const windowHeight = ref(undefined);

export const adjustHeights = () => {
  navbarFixedHeight.value = get(navbarFixedTop.value, "offsetHeight", 0);
  footerHeight.value = isFooterFixed.value ? get(footer.value, "offsetHeight", 0) : 0;
  windowHeight.value = window.innerHeight;
  asideFixedHeight.value = window.innerHeight - navbarFixedHeight.value - footerHeight.value;
};

const checkHeights = () => {
  setTimeout(() => {
    const isNavbarChanged = navbarFixedHeight.value !== get(navbarFixedTop.value, "offsetHeight", 0);
    const isFooterChanged = isFooterFixed.value ?
      footerHeight.value !== get(footer.value, "offsetHeight", 0) :
      footerHeight.value !== 0;
    const isWindowChanged = windowHeight.value !== window.innerHeight;

    if (isNavbarChanged || isFooterChanged || isWindowChanged) {
      adjustHeights();
    }
  });
};

export const toggleFixedFooter = () => {
  const WINDOW_HEIGHT = window.innerHeight;
  const FOOTER_TOP = get(footer.value?.getBoundingClientRect(), "top", WINDOW_HEIGHT);
  isFooterFixed.value = FOOTER_TOP <= WINDOW_HEIGHT;
  adjustHeights();
};

export default function LayoutAPI() {
  const initResizeObserver = () => {
    const resizeObserver = new ResizeObserver(
      debounce(() => {
        if (navbarFixedHeight.value === 0 || navbarFixedHeight.value !== get(navbarFixedTop.value, "offsetHeight", 0)) {
          adjustHeights();
        }
      }, 300)
    );
    resizeObserver.observe(navbarFixedTop.value);
  };
  const initEventListener = () => {
    window.addEventListener("resize", checkHeights);
  };

  return {
    adjustHeights,
    asideFixedHeight,
    footer,
    initEventListener,
    initResizeObserver,
    navbarFixedHeight,
    navbarFixedTop,
  };
}
