import UiValidatedJsonReadOnly from "../../../../../global/components/ui/UiValidatedJson/UiValidatedJsonReadOnly/UiValidatedJsonReadOnly.vue";

import UIReadOnlyMixin from "../../../../../global/components/ui/UIReadOnlyMixin";

import OptionsAPI from "../compositionAPI/OptionsAPI";

// @vue/component
export default {
  name: "UiPauschalePeriodenConfigReadOnly",
  components: {
    UiValidatedJsonReadOnly,
  },
  mixins: [
    UIReadOnlyMixin,
  ],
  setup(props) {
    const {
      jsonOptions
    } = OptionsAPI(props);

    return {
      jsonOptions,
    };
  }
};
